<template>
  <div class="table">
    <el-table ref="multipleTable" :data="tableData" :highlight-current-row="true" tooltip-effect="dark"
      style="width:100%;color:#858585;" @selection-change="selectRow" :header-cell-style="tableHeaderCellStyle">
      <el-table-column type="selection" fill="#00CCCC" fixed="left">
      </el-table-column>
      <!-- 表头开始 -->
      <el-table-column prop="orgName" label="机构名称" align="center">
      </el-table-column>
      <el-table-column prop="orgType" label="机构类型" align="center">
      </el-table-column>
      <el-table-column label="机构编号" width="170" prop="orgCode" align="center">
      </el-table-column>
      <el-table-column prop="orgContactsName" label="机构负责人" align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="orgContactsMobile" label="负责人联系方式" align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="orgCityName" label="机构所在地" align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="orgStartTime" align="center" label="机构创建时间" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" label="操作" width="200">
        <template slot-scope="scope">
          <span style="color:#00cccc;cursor: pointer;margin-right: 20px;" @click="clickRow(scope.row)">查看详情</span>
          <span style="color:#00cccc;cursor: pointer;" @click="clickRowToken(scope.row)">RefreshToken</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="page">
      <!-- left -->
      <span class="pageLeft">共 {{ count }} 条记录 第 {{ currentPage }} / {{ tatalPage }}页</span>
      <!-- right -->
      <el-pagination @size-change="SizeChange" background @current-change="CurrentChange" @prev-click="prevClick"
        @next-click="nextClick" :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="onlyPage"
        :pager-count="5" layout="sizes, prev, pager, next, jumper" :total="count">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getOrgList, genOrgRefreshToken } from '@/api/orgController.js'
export default {
  data() {
    return {
      tableData: [],//表格数据
      multipleSelection: [],
      // 分页相关
      currentPage: 1,
      tatalPage: 0,
      count: 0,
      onlyPage: 10,
    }
  },
  mounted() {
    //初始化获取列表数据
    this.getData(1, 10, null, null); //暂时先不去请求数据
  },
  //监听勾选值
  watch: {
    multipleSelection: {
      handler(pre) {
        var tem = pre;
        if (tem.length == 0) {
          this.$parent.isDelete = true;
        }
        if (tem.length >= 1) {
          this.$parent.isDelete = false;
          this.$parent.selectId = tem;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    //表头样式回调
    tableHeaderCellStyle() {
      return "color:#fff;background-color:#00CCCC";
    },
    // getData
    getData(currentPage, onlyPage, orgType, allSeach) {
      const rLoading = this.openLoading();// 开始loading
      getOrgList({
        currentPage: currentPage,
        pageSize: onlyPage,
        orgType: orgType,
        orgName: allSeach
      }).then((res) => {
        if (res.data.status == 200) {
          this.tableData = res.data.data.records;
          this.tatalPage = parseInt(res.data.data.size);
          this.count = parseInt(res.data.data.total);
          rLoading.close();// 关闭loading
        } else {
          this.$message.error(res.data.message);
        }
      }).catch(res => {
        this.$message.error(res);
      })
    },
    // 复选框选项
    selectRow(val) {
      this.multipleSelection.length = 0;
      this.multipleSelection = val;
    },

    // 分页相关
    SizeChange(val) {
      // pageSize 改变时会触发  回调参数 每页条数
      // console.log("每页多少条",val);
      this.onlyPage = val;
      this.getData(this.currentPage, this.onlyPage, this.$parent.orgType, this.$parent.allSearch);//更新数据
    },
    CurrentChange(val) {
      // currentPage 改变时会触发  回调参数 当前页
      // console.log("当前页面",val);
      this.currentPage = val;
      this.getData(this.currentPage, this.onlyPage, this.$parent.orgType, this.$parent.allSearch);//更新数据
    },
    prevClick(val) {
      // 上一页
      // console.log("上一页的页数是",val);
      this.currentPage = val;
      this.getData(this.currentPage, this.onlyPage, this.$parent.orgType, this.$parent.allSearch);//更新数据

    },
    nextClick(val) {
      // 下一页
      // console.log("下一页的页数是",val);
      this.currentPage = val;
      this.getData(this.currentPage, this.onlyPage, this.$parent.orgType, this.$parent.allSearch);//更新数据

    },
    clickRow(e) {
      this.$router.push({
        path: '/orgDetail',
        query: {
          id: e.id
        }
      })
    },
    async clickRowToken(e) {
      await this.genOrgRefreshTokenAction(e.id)
    },
    // 生成机构RefreshToken
    genOrgRefreshTokenAction(id) {
      genOrgRefreshToken({
        id
      }).then(res => {
        if (res.data.status == 200) {
          this.$alert(`RefreshToken: ${res.data.data}`, '重要信息，请注意保存在本地', {
            confirmButtonText: '确定'
          });
        } else {
          this.$message.error(res.data.message);
        }
      }).catch(res => {
        this.$message.error(res);
      })
    },

  },
  computed: {

  }
}
</script>
<style scoped>
.table {
  margin-top: .1563rem;

}

.el-button {
  color: #00A3A3 !important;
}

.action {
  display: flex;
  flex-direction: column;
}

.actionP {
  cursor: pointer;
  color: #00CCCC;
  margin: 0 !important;
}

.resoult {
  text-align: left;
}

.page {
  display: flex;
  margin-top: .3125rem;
  justify-content: space-between;
  align-items: center;
}

.pageLeft {
  color: #858585;
  font-size: .1094rem;
  line-height: .1875rem;
}

.el-pagination {
  color: #858585 !important;
  font-size: .1094rem !important;
  line-height: .1875rem !important;
}
</style>