<template>
<!-- 机构管理 -->
  <div class="agency">
    <!-- top -->
      <el-breadcrumb separator="/">
          <el-breadcrumb-item>系统管理</el-breadcrumb-item>
          <el-breadcrumb-item>机构管理</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- content -->
      <div class="main">
        <!-- table top -->
        <div class="tableTop">
          <div class="topLeft">
              <div class="create" @click="createOrg">创建机构</div>
              <el-dropdown trigger="click" @command="selectType">
                <div class="create" style="margin-left:24px" type="primary">
                  机构类型<i class="el-icon-arrow-down el-icon--right"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="null">全部</el-dropdown-item>
                  <el-dropdown-item command="检测机构">检测机构</el-dropdown-item>
                  <el-dropdown-item command="受检机构">受检机构</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
          </div>
          
          <div class="topRight">
                <div class="search">
                <el-input
                  placeholder="请输入内容"
                  prefix-icon="el-icon-search"
                  class="elSerch"
                  @change="startSearch"
                  v-model="allSearch">
                    <template slot="append" @click="startSearch">搜索</template>
                </el-input>
              </div>
              <div  v-if="!isDelete" class="delete" @click="deleteOrg">删除</div>
              <div v-if="isDelete" class="deleteNo">删除</div>
          </div>
          </div>
          
        </div>
        <!-- table -->
          <OrgTable ref="orgTable"/>
          <!-- alert弹窗 -->
      <el-dialog
        title="添加机构"
        :visible.sync="isCreate"
        width="390px"
        center>
       <!-- 内容区 -->
        <el-form ref="orgForm" :rules="rules" :model="orgForm" label-width="110px">
          <el-form-item label="机构名称" prop="orgName">
            <el-input v-model="orgForm.orgName" style="width:200px !important"></el-input>
          </el-form-item>
          <el-form-item label="机构类型" prop="orgType">
            <el-select v-model="orgForm.orgType" placeholder="请选择机构类型" @change="selectOrgType" clearable size="medium">
                <el-option v-for="(item,index) in orgTypeList" :key="index"
                    :label="item.name"
                    :value="item.id">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="机构负责人" prop="contactName">
            <el-input v-model="orgForm.contactName" style="width:200px !important"></el-input>
          </el-form-item>
          <el-form-item label="负责人号码" prop="mobile">
            <el-input v-model="orgForm.mobile" style="width:200px !important"></el-input>
          </el-form-item>
          <el-form-item label="机构邮箱" prop="email">
            <el-input v-model="orgForm.email" style="width:200px !important"></el-input>
          </el-form-item>
          <el-form-item label="机构地区" prop="checkCity">
            <el-cascader v-model="orgForm.checkCity" :options="district" clearable style="width:200px !important"></el-cascader>
          </el-form-item>
          <el-form-item label="机构地点" prop="address">
            <el-input type="textarea" v-model="orgForm.address" style="width:200px !important"></el-input>
          </el-form-item>
        </el-form>
        <!-- footer -->
        <div style="width:440px;display:flex;justify-content:space-around;margin-top:60px;margin-left:-50px">
          <div style="width:80px;
            height:32px;
            font-size:16px;
            color:#fff;cursor:pointer;
            background-color: #00cccc;
            line-height: 32px; border-radius:4px;  
            text-align: center" @click="cancelOrg">取消</div>
            <div style="width:80px;
            height:32px;
            font-size:16px;
            color:#fff;cursor:pointer;
            background-color: #00cccc;
            line-height: 32px; border-radius:4px;  
            text-align: center" @click="sureOrg('orgForm')">确认</div>
        </div>
      </el-dialog>
  </div>
</template>
<script>
import OrgTable from '@/components/table/orgTable.vue'
import { district } from '@/assets/js/cityCode.js'
import {newOrg,viewOrg} from '@/api/orgController.js'
export default {
  beforeRouteLeave(to,from,next){
    from.meta.keepAlive = false;
    next();
  },
components:{
  OrgTable,
  },

data(){
  //自定义规则
  var mobile = (rule, value, callback) => {
            let reg =/^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
                if (!value) {
                    callback(new Error('手机号不能为空'));
                } else {
                    if (!reg.test(value)) {
                        callback(new Error('手机号码格式不正确'));
                    }
                        callback();
                    }
            };
  return{
    district,
    allSearch:null,
    isDelete:true,//是否可以删除
    isCreate:false,//添加机构控件
    orgType:null,
    orgForm:{
      orgName:null,// 
      orgType:null,
      contactName:null,//负责人
      mobile:null,//负责联系方式
      checkCity:null,
      address:null,
      email:null
    },
    //orgTypeList
    orgTypeList:[
      {name:'检测机构',id:'检测机构'},
      {name:'受检机构',id:'受检机构'}
    ],
    //校验规则
    rules:{
             orgName: [ { required: true, message: '请填写机构名', trigger: 'blur' }],
             orgType: [ { required: true, message: '请选择机构类型', trigger: 'blur' }],
             email:[{ type: 'email', required: false, message: '请正确填写邮箱', trigger: 'blur' }],
             address:[ { required: true, message: '请填写地址', trigger: 'blur' }],
             checkCity:[ { required: true, message: '请选择城市', trigger: 'blur' }],
             contactName:[ { required: true, message: '请填写机构负责人', trigger: 'blur' }],
             mobile:[ { required: true, validator: mobile, message: '请正确填写手机号码', trigger: 'blur' }],
        }
  }
},

methods:{
 
  //创建机构控件
  createOrg(){
    this.isCreate =true;
  },
  //
  selectOrgType(value){
    this.orgForm.orgType = value;
  },
  //确认创建机构
  sureOrg(formName){
    this.$refs[formName].validate((valid) => {
          if (valid) {
            newOrg({
                orgName:this.orgForm.orgName,
                orgType:this.orgForm.orgType,
                orgEmail:this.orgForm.email,
                orgProvinceCode:this.orgForm.checkCity[0],
                orgCityCode:this.orgForm.checkCity[1],
                orgAddress:this.orgForm.address,
                orgContactsName:this.orgForm.contactName,
                orgContactsMobile:this.orgForm.mobile
              }).then(res=>{
                if(res.data.status == 200){
                  this.$message({
                    message: '添加成功！',
                    type: 'success'
                  });
                  //clear
                  for(var el in this.orgForm) {
                      this.orgForm[el] = "";
                  };
                  //close
                  this.isCreate = false;
                  //refresh
                  this.$refs.orgTable.getData(1,10,null,null);
                }
                else {
                    this.$message.error(res.data.message);
                  }
              }).catch(res=>{
                 this.$message.error(res);
              })
          } else {
              this.$message.error("请正确填写相应内容！");
              return false;
          }
        });
  },
  //取消创建机构
  cancelOrg(){
    //close
     this.isCreate =false;
    //clear
    for(var el in this.orgForm) {
      this.orgForm[el] = "";
      };
  },
  //选择类型查询
  selectType(e){
      var temOrgType = e;
      if(temOrgType == "null"){
        temOrgType = null;
        this.orgType = temOrgType;
        this.$refs.orgTable.getData(1,10,this.orgType,null);
      }else{
        this.orgType = [temOrgType];
         this.$refs.orgTable.getData(1,10,this.orgType,null);
      }
      
      
  },
  //delete ORG
  deleteOrg(){
    this.$message.error("暂不支持！");
  },
  //startSearch
  startSearch(){
    this.$refs.orgTable.getData(1,10,this.orgType,this.allSearch);
  }
}
}
</script>

<style lang="stylus" scoped>
//change elemen-ui style

//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }
//搜索框样式--边框颜色
.elSerch>>>.el-input__inner
    border: 1px solid #00cccc !important
    width: 200px
    border-radius: 4px 0 0 4px
//搜索框样式--高度
/deep/.el-input__inner{
    height: 32px;
  }
//搜索框样式--搜索部分样式
/deep/.el-input-group__append{
    border-block-color:#00CCCC !important;
    color: #fff !important;
    background-color: #00CCCC !important;
    border: 0 !important;
  }

//nodelete
.deleteNo
  width: 64px
  height: 32px
  background-color: #F4f4f4
  text-align: center
  line-height:32px
  color: #aeaeae
  font-size: 14px
  margin-left: 16px
  cursor not-allowed
  border-radius: 4px

//nomal style
.agency
  overflow hidden
  .main 
    overflow hidden
    margin-top: 16px
    padding: 20px
    background-color: #FFFFFF
    .tableTop 
      display: flex
      justify-content: space-between
      .topLeft
        display: flex
        .create 
          width: 116px 
          height 32px 
          background-color:#00CCCC
          cursor pointer
          color: #fff
          line-height: 32px 
          text-align: center
          font-size: 14px
      .topRight
        display: flex
        .search
          height: 32px
          margin-left: 484px
        .delete
            width: 64px
            height: 32px
            background-color: #FC5555
            text-align: center
            line-height:32px
            color: #fff
            font-size: 14px
            margin-left: 16px
            cursor pointer
            border-radius: 4px

</style>>

